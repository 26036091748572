.chat-box .titlebar {
  background-color: var(--mdc-theme-primary, #1977ff);
  padding: .4rem 0;
  min-height: 32px;
}

.chat-box .titlebar .logo {
  width: auto;
  padding-left: .8rem;
  padding-top: .2rem;
  height: 40px;
  margin: 0 auto;
}

.chat-box .messages {
  height: 100%;
  max-height: 100%;
  flex: 1 1 1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #fdfdfd;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.chat-box .messages .message {
  --message-margin: 1.2rem;
  --bubble-color: #e2e2e2;
  --radius: .6rem;
  color: #292929;
  margin-top: calc(var(--message-margin) / 2);
  margin-bottom: calc(var(--message-margin) / 2);
  padding: .8rem;
  max-width: 55%;
  min-width: 3rem;
  border-radius: var(--radius);
  position: relative;
  background: var(--bubble-color);
}

.chat-box .messages .message.first {
  margin-top: var(--message-margin);
}

.chat-box .messages .message.last {
  margin-bottom: calc(var(--message-margin) * 1.5);
}

.chat-box .messages .message.right {
  --bubble-color: var(--mdc-theme-primary, #1977ff);
  color: #fff;
  margin-left: auto;
  margin-right: calc(var(--message-margin) / 2);
  border-bottom-right-radius: 0;
}

.chat-box .messages .message.right:before {
  --border-size: var(--radius);
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-right: var(--border-size) solid var(--bubble-color);
  border-left: var(--border-size) solid transparent;
  border-top: var(--border-size) solid var(--bubble-color);
  border-bottom: var(--border-size) solid transparent;
  right: 0;
  bottom: calc(var(--border-size) * -1);
}

.chat-box .messages .message.left {
  background: var(--bubble-color);
  margin-right: auto;
  margin-left: calc(var(--message-margin) / 2);
  border-bottom-left-radius: 0;
}

.chat-box .messages .message.left:before {
  --border-size: var(--radius);
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: var(--border-size) solid var(--bubble-color);
  border-right: var(--border-size) solid transparent;
  border-top: var(--border-size) solid var(--bubble-color);
  border-bottom: var(--border-size) solid transparent;
  left: 0;
  bottom: calc(var(--border-size) * -1);
}

.chat-box .messages .message.left.error {
  --bubble-color: var(--mdc-theme-error, #d43551);
  color: #fff;
}

.chat-box .messages .message img {
  height: auto;
  width: 100%;
  max-width: 100%;
}

.chat-box .messages .message audio {
  height: 42px;
  max-width: 100%;
}

.chat-box .messages .message p {
  line-height: 1.5rem;
  margin-bottom: .4rem;
}

.chat-box .messages .message small {
  font-size: .7rem;
}

.button-list {
  border-radius: 9px;
  border: solid 1px #1977ff;
  background-color: #fff;
  color: #1977ff;
  height: 45px !important;
  margin-bottom: 8px;
  text-align: center;
}

.button-list:hover {
  border: solid 1px #1977ff;
  background-color: #1977ff;
  color: #fff;
}

.button-card {
  margin: 0rem 1rem 0rem;
  padding: 1rem;
  font-size: small;
}

.size-buttons-list {
  font-size: small;
  text-align: left;
  line-height: normal;
}

.image-dialog.mdc-dialog .mdc-dialog__content {
  padding: 0.5em;
}

.image-dialog.mdc-dialog .mdc-dialog__surface {
  max-width: 95%;
}

.btn-close.mdc-button {
  position: fixed;
  right: 15px;
}

.file-styles {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 720px) {
  .chat-box .messages .message.right {
    margin-right: var(--message-margin);
  }
}

.chat-box .mdc-button.mic,
.chat-box .mdc-button.mic .mdc-button__ripple {
  border-radius: 0 0 0 0;
  border-right: 1px solid #e0e0e0;
}

.chat-box .mdc-button.mic .mdi:before {
  margin-right: .8rem;
}

.recording-button {
  z-index: 1000;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  margin-left: 10px;
  background: white;
  border-radius: 50px;
  border: none;
  text-align: center;
  color: var(--mdc-theme-primary, #6200ee);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  justify-content: center;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#btn {
  display: none;
}

.buttons {
  display: flex;
  align-content: center;
  vertical-align: middle;
}

.icon {
  z-index: 1000;
  cursor: pointer;
  display: flex;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  margin-left: 10px;
  background: white;
  border-radius: 50px;
  text-align: center;
  color: var(--mdc-theme-primary, #6200ee);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
  justify-content: center;
  flex-direction: column;
}

#btn:checked ~ .buttons label {
  display: flex;
  width: 50px;
  height: 50px;
  line-height: 15px;
  align-content: center;
  transition: all 500ms cubic-bezier(0.41, 0.13, 0.64, 0.83);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-right: 10px;
}

.buttons a {
  display: inline-block;
  margin: 2px;
  text-decoration: none;
  color: var(--mdc-theme-primary, #6200ee);
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
  transition: all 200ms cubic-bezier(0.41, 0.13, 0.64, 0.83);
}

.buttons a:hover {
  background: #fff;
  color: var(--mdc-theme-primary, #6200ee);
  transform: scale(1.025);
  transition: all 200ms cubic-bezier(0.41, 0.13, 0.64, 0.83);
}

.buttons a {
  margin-left: -55px;
  opacity: 0;
  visibility: hidden;
}

#btn:checked ~ .buttons a {
  margin-left: auto;
  opacity: 1;
  visibility: visible;
  margin-right: 5px;
  transition: all 200ms cubic-bezier(0.41, 0.13, 0.64, 0.83);
}

.column {
  padding: 0;
}

.style-input {
  resize: none;
  padding-top: 0.5rem;
}

.chat-box {
  margin: auto;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media only screen and (max-width: 720px) {
  .hero-body {
    padding: 0
  }
}

.chat-box .chat-input {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.style-emoji {
  display: flex;
  align-items: center;
  margin: 2px;
  padding-left: 3px;
  color: rgb(98, 96, 96);
  cursor: pointer;
}

.style-send-button {
  z-index: 1000;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  background: white;
  border-radius: 50px;
  border: none;
  text-align: center;
  color: var(--mdc-theme-primary, #6200ee);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  justify-content: center;
}

.style-input {
  resize: none;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  padding-left: 13px;
  margin-left: 5px;
  border-radius: 20px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
}

.file-style {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
}

.file-style p{
  margin: 5px;
  padding: 2px;
}
.file-style a {
  color: #fdfdfd;
}

.chat-box .mdc-text-field {
  width: 100%;
}

.chat-box .mdc-text-field,
.chat-box .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.chat-box .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.chat-box .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-bottom-right-radius: 0;
}

.chat-box .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.chat-box .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.chat-box .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-left-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-right-color: #e0e0e0;
  border-top-color: #e0e0e0;
}

.chat-box .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.chat-box .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.chat-box .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #1977ff);
}

.chat-box .mdc-button {
  height: 55px;
  width: 56px;
  padding-left: 1.4rem;
}

.chat-box .mdc-button,
.chat-box .mdc-button .mdc-button__ripple {
  border-radius: 0 0 4px 0;
}

.chat-box .mdc-button .mdi {
  margin-right: .8rem;
  margin-bottom: .1rem;
}
